import { Breadcrumb } from '@/Models'
import menuModule from '@/store/menu'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BreadCrumb extends Vue {
  get breadcrumbs(): Breadcrumb[] {
    return menuModule.breadcrumbs
  }
}
